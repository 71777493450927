var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-header text-center bg-primary"
  }, [_c('h3', [_vm._v("Edit Client")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('h5', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.client.name
      }
    }
  }, [_c('span', {
    staticClass: "badge badge-primary"
  }, [_c('i', {
    staticClass: "fa fa-rotate-left mr-1"
  }), _vm._v("Back")])])], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-3"
  }), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Name"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "type client name..."
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.name = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("App Name"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" : "), _c('i', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "fa fa-question-circle",
    attrs: {
      "title": "Can't use space character"
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.appName,
      expression: "appName"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "ebook_digital_android"
    },
    domProps: {
      "value": _vm.appName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.appName = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Application :")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Application...",
      "open-direction": "bottom",
      "options": _vm.items_application,
      "searchable": true,
      "loading": _vm.isLoadingSearchApplication,
      "close-on-select": true
    },
    model: {
      value: _vm.application,
      callback: function ($$v) {
        _vm.application = $$v;
      },
      expression: "application"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v(" Oops! No elements found. Consider changing the search query. ")])])], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Platform"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]), _c('multiselect', {
    attrs: {
      "label": "text",
      "track-by": "text",
      "placeholder": "Platform",
      "open-direction": "bottom",
      "options": _vm.platformOption,
      "searchable": true,
      "loading": _vm.isLoadingPlatform,
      "close-on-select": true,
      "select": _vm.platform
    },
    model: {
      value: _vm.platform,
      callback: function ($$v) {
        _vm.platform = $$v;
      },
      expression: "platform"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v(" Oops! No elements found. Consider changing the search query. ")])])], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Description :")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.description,
      expression: "description"
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "4",
      "placeholder": "type description about client..."
    },
    domProps: {
      "value": _vm.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.description = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Version"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" : ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.version,
      expression: "version"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "1.0.0"
    },
    domProps: {
      "value": _vm.version
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.version = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Lowest Supported Version"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" : ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.lowestSupportedVersion,
      expression: "lowestSupportedVersion"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "1.0.0"
    },
    domProps: {
      "value": _vm.lowestSupportedVersion
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.lowestSupportedVersion = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Lowest OS Version"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" : ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.lowestOsVersion,
      expression: "lowestOsVersion"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "1.0.0"
    },
    domProps: {
      "value": _vm.lowestOsVersion
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.lowestOsVersion = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Product ID"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" : ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productID,
      expression: "productID"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "com.appsfoundry.scoop"
    },
    domProps: {
      "value": _vm.productID
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.productID = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isActive,
      expression: "isActive"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "is_active"
    },
    domProps: {
      "checked": Array.isArray(_vm.isActive) ? _vm._i(_vm.isActive, null) > -1 : _vm.isActive
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.isActive,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.isActive = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isActive = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isActive = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "is_active"
    }
  }, [_vm._v("Is Active")])]), _c('div', {
    staticClass: "form-check"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.restrictedContent,
      expression: "restrictedContent"
    }],
    staticClass: "form-check-input",
    attrs: {
      "type": "checkbox",
      "id": "restricted_content"
    },
    domProps: {
      "checked": Array.isArray(_vm.restrictedContent) ? _vm._i(_vm.restrictedContent, null) > -1 : _vm.restrictedContent
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.restrictedContent,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.restrictedContent = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.restrictedContent = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.restrictedContent = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "restricted_content"
    }
  }, [_vm._v("Restricted Content")])]), _c('br'), _c('br'), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        return _vm.editClient();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pencil"
  }), _vm._v(" Update Client ")])]), _c('div', {
    staticClass: "col-sm-3"
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }