<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card mt-2">
      <div class="card-header text-center bg-primary">
        <h3>Edit Client</h3>
      </div>
      <div class="card-body">
        <h5>
          <router-link
            :to="{
              name: client.name,
            }"
          >
            <span class="badge badge-primary"
              ><i class="fa fa-rotate-left mr-1"></i>Back</span
            >
          </router-link>
        </h5>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="organization"
                >Name<span class="text-danger">*</span> :</label
              >
              <input
                v-model="name"
                type="text"
                class="form-control"
                placeholder="type client name..."
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >App Name<span class="text-danger">*</span> :
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Can't use space character"
                ></i>
              </label>
              <input
                v-model="appName"
                type="text"
                class="form-control"
                placeholder="ebook_digital_android"
              />
            </div>

            <div class="form-group">
              <label>Application :</label>
              <multiselect
                v-model="application"
                label="name"
                track-by="name"
                placeholder="Application..."
                open-direction="bottom"
                :options="items_application"
                :searchable="true"
                :loading="isLoadingSearchApplication"
                :close-on-select="true"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>

            <div class="form-group">
              <label>Platform<span class="text-danger">*</span> :</label>
              <multiselect
                v-model="platform"
                label="text"
                track-by="text"
                placeholder="Platform"
                open-direction="bottom"
                :options="platformOption"
                :searchable="true"
                :loading="isLoadingPlatform"
                :close-on-select="true"
                :select="platform"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>

            <div class="form-group">
              <label for="organization">Description :</label>
              <textarea
                v-model="description"
                class="form-control"
                rows="4"
                placeholder="type description about client..."
              ></textarea>
            </div>

            <div class="form-group">
              <label>Version<span class="text-danger">*</span> : </label>
              <input
                v-model="version"
                type="text"
                class="form-control"
                placeholder="1.0.0"
              />
            </div>

            <div class="form-group">
              <label
                >Lowest Supported Version<span class="text-danger">*</span> :
              </label>
              <input
                v-model="lowestSupportedVersion"
                type="text"
                class="form-control"
                placeholder="1.0.0"
              />
            </div>

            <div class="form-group">
              <label
                >Lowest OS Version<span class="text-danger">*</span> :
              </label>
              <input
                v-model="lowestOsVersion"
                type="text"
                class="form-control"
                placeholder="1.0.0"
              />
            </div>

            <div class="form-group">
              <label>Product ID<span class="text-danger">*</span> : </label>
              <input
                v-model="productID"
                type="text"
                class="form-control"
                placeholder="com.appsfoundry.scoop"
              />
            </div>

            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="is_active"
                v-model="isActive"
              />
              <label class="form-check-label" for="is_active">Is Active</label>
            </div>

            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="restricted_content"
                v-model="restrictedContent"
              />
              <label class="form-check-label" for="restricted_content"
                >Restricted Content</label
              >
            </div>

            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="editClient()"
            >
              <i class="fa fa-pencil"></i>
              Update Client
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
import { client } from '../../router/clients';

const platformID = Object.entries(constant.PLATFORM).map((val) => ({
  text: val[0].replace('_', ' '),
  value: val[1],
}));

export default {
  name: 'ClientEdit',
  data() {
    return {
      client,
      name: null,
      appName: null,
      application: [],
      items_application: [],
      isLoadingSearchApplication: false,
      platform: null,
      isLoadingPlatform: false,
      platformOption: [...platformID],
      description: '',
      version: '',
      lowestSupportedVersion: '',
      lowestOsVersion: '',
      productID: '',
      isActive: true,
      restrictedContent: true,
    };
  },
  watch: {
    item: function() {
      if (!Object.keys(this.item).length) return;
      this.getClient();
    },
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/clients');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },
    applicationItems: function() {
      if (!Object.keys(this.applicationItems).length) return;
      this.items_application = this.applicationItems;
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Edit Client | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.client.isLoading,
      isError: (state) => state.client.isError,
      successMessage: (state) => state.client.SuccessMessage,
      errorMessage: (state) => state.client.ErrorMessage,
      item: (state) => state.client.item,
      applicationItems: (state) => state.application.items,
    }),
  },
  created: function() {
    const clientID = this.$route.params.id;
    this.fetchClientByID({ id: clientID });
    this.actionGetApplication();
  },
  methods: {
    ...mapActions('client', ['fetchClientByID', 'updateClient']),
    ...mapActions('application', ['fetchApplication']),

    actionGetApplication() {
      const payload = {
        status: true,
        limit: 150,
      };
      this.fetchApplication(payload);
    },

    getClient() {
      const data = this.item;
      this.name = data.name;
      this.appName = data.app_name;
      this.application = data.app;
      this.platform = platformID.find((v) => v.value == data.platform_id);
      this.description = data.description;
      this.version = data.version;
      this.lowestSupportedVersion = data.lowest_supported_version;
      this.lowestOsVersion = data.lowest_os_version;
      this.productID = data.product_id;
      this.isActive = data.is_active;
      this.restrictedContent = data.allow_age_restricted_content;
    },

    editClient() {
      const clientID = this.$route.params.id;
      const payload = {
        id: clientID,
        name: this.name,
        app_name: this.appName,
        application_id: this.application ? this.application.id : null,
        platform_id: this.platform ? this.platform.value : null,
        description: this.description,
        version: this.version,
        lowest_supported_version: this.lowestSupportedVersion,
        lowest_os_version: this.lowestOsVersion,
        product_id: this.productID,
        is_active: this.isActive,
        allow_age_restricted_content: this.restrictedContent,
      };

      this.updateClient(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
